<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What is the <stemble-latex content="$\text{pH}$" /> of a
        <number-value :value="molarity" unit="\text{M}" /> solution of
        <chemical-latex content="NaOH" />?
      </p>

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
        class="mb-3"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemSIU131_Task22',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        pH: null,
      },
    };
  },
  computed: {
    molarity() {
      return this.taskState.getValueBySymbol('molarity').content;
    },
  },
};
</script>
